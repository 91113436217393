import { useContext, useEffect, useState }  from "react";
import ApprovalUserListHeader from "../conponents/ApprovalUserListHeader";
import ApprovalUserListTable from "../conponents/ApprovalUserListTable";
import GenericTemplate from "../templates/GenericTemplate";
import * as commonFnc from '../common/commonFnc';
import { GlobalContext } from "../globalState";
import '../css/Loading.css';


const ApprovalUserList = (props) =>{
    const {
        login_user_id,
        setLogin_user_id, 
        Users, 
        Shift_msts,
        Department_msts, 
        setKintai_bscs, 
        setShiftStartDay, 
        setDisplay_user_info, 
        Login_user_info
    } = props;

    const {serchCondition, setSerchCondition} = useContext(GlobalContext);
    const [kintaiSummaryInfos, setKintaiSummaryInfos] = useState([]);
    const [allUser_Kintai_bscs, setAllUser_Kintai_bscs] = useState([]);

    useEffect(()=>{
        // setSerchCondition({
        //     ...serchCondition,
        //     // target_deptgr_cd : Login_user_info.organization_cd,
        //     // target_dept_cd : Login_user_info.department_cd,
        // })
        
        getApproval_kintai_bsc_List();
    },[])

    // 勤怠情報
    useEffect(async ()=>{

        getApproval_kintai_bsc_List();

        // const query_kintai = new URLSearchParams({target_date_from: commonFnc.formatDate(serchCondition.target_date_from), target_date_to: commonFnc.formatDate(serchCondition.target_date_to)});
        // await fetch(`/api/select/allUser_term_kintai_bsc?${query_kintai}`)
        //     .then((res) => res.json())
        //     .then((data) => setAllUser_Kintai_bscs(data));

        // const query = new URLSearchParams({user_id: login_user_id, target_date_from: commonFnc.formatDate(serchCondition.target_date_from), target_date_to: commonFnc.formatDate(serchCondition.target_date_to)});
        // await fetch(`/api/select/kintaiSummaryInfos?${query}`)
        //   .then((res) => res.json())
        //   .then((data) => setKintaiSummaryInfos(data));
          
    },[login_user_id])



    // 03_独自関数
    
    const [loading, setLoading] = useState(false);
    // 承認待ち情報一覧を取得する。
    const getApproval_kintai_bsc_List = async () =>{
        setLoading(true);

        const query_kintai = new URLSearchParams({target_date_from: commonFnc.formatDate(serchCondition.target_date_from), target_date_to: commonFnc.formatDate(serchCondition.target_date_to)});
        await fetch(`/api/select/allUser_term_kintai_bsc?${query_kintai}`)
            .then((res) => res.json())
            .then((data) => setAllUser_Kintai_bscs(data));

        const query = new URLSearchParams({
            user_id: login_user_id, 
            authority: Login_user_info.authority, 
            organization_cd: Login_user_info.organization_cd, 
            target_date_from: commonFnc.formatDate(serchCondition.target_date_from), 
            target_date_to: commonFnc.formatDate(serchCondition.target_date_to)
        });
        await fetch(`/api/select/kintaiSummaryInfos?${query}`)
          .then((res) => res.json())
          .then((data) => setKintaiSummaryInfos(data));
        
        setLoading(false);
    }


    return (
        <GenericTemplate title="勤務集計"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        {!Login_user_info ? <div class="loader">Loading...</div> :  
            <div>
                <ApprovalUserListHeader
                    Shift_msts={Shift_msts}
                    Department_msts={Department_msts}
                    serchCondition={serchCondition}
                    setSerchCondition={setSerchCondition}
                    getApproval_kintai_bsc_List={getApproval_kintai_bsc_List}
                    Login_user_info={Login_user_info}
                />
                <ApprovalUserListTable 
                    kintaiSummaryInfos={kintaiSummaryInfos}
                    Users={Users}
                    Department_msts={Department_msts}
                    setKintai_bscs={setKintai_bscs}
                    setShiftStartDay={setShiftStartDay}
                    serchCondition={serchCondition}
                    setDisplay_user_info={setDisplay_user_info}
                    allUser_Kintai_bscs={allUser_Kintai_bscs}
                    loading={loading}
                />
            </div>
        }
        </GenericTemplate>
    );
}

export default ApprovalUserList;
import { useEffect, useState } from "react";
import CommonIdCheckDialog from "../common/CommonIdCheckDialog";
import CommonMsgDialog from "../common/CommonMsgDialog"
import DepMstMemberEditModal from "../conponents/DepMstMemberEditModal";
import GenericTemplate from "../templates/GenericTemplate";
import * as commonFnc from '../common/commonFnc';
import DepMstMenberGrid from "../conponents/DepMstMenberGrid";



// name: Mst_UserMstPage
// summary: 部署マスタ編集画面
// prop: setLogin_user_id
// prop: setKintai_bscs
// prop: Login_user_info
// prop: setDisplay_user_info
// prop: Users ユーザ情報
// prop: setUsers ユーザ情報のセッター
// prop: Department_msts 部署情報
const Mst_UserMstPage = (props) =>{
    
    // 01_props
    const {setLogin_user_id, setKintai_bscs, Login_user_info, setDisplay_user_info, Users, setUsers, Department_msts, setShiftStartDay} = props;
    
    // 02_useState
    const [Department_mst, setDepartment_mst] = useState({
        department_cd: "",
        name: "",
        highly_department_cd: "",
        department_type: 1,
        first_approval_userid:"",
        second_approval_userid:"",
        use_start_day: "",
        use_end_day: ""
    })
    
    const [public_holiday_type_msts, setPublic_holiday_type_msts] = useState([]);
    useEffect(async ()=>{
        try {
            const res = await fetch(`/api/select/public_holiday_type_msts`);
            const data = await res.json();
            setPublic_holiday_type_msts(data);
        } catch (error) {
            console.error(error);
        }
    },[])
    
    // 新規作成用
    const [User, setUser] = useState({
        user_id: "",
        pw: "",
        first_name: "",
        second_name: "",
        first_kana_name: "",
        second_kana_name: "",
        authority:10,
        entrer_date: "19800701",
        retire_date: "19800701",
        employment_status_cd:"1",
        work_style_cd:"1",
        organization_cd:"999",
        department_cd:"999",
        first_approval_userid:"",
        second_approval_userid:"",
        enable_public_holiday_days: 10,
        enable_paid_holiday_days: 10,
        if_cd:"1",
        start_paid_holiday_add_day:"",
        notes:"",
        notes2:"",
        notes3:"",
    })

    // 新規作成モーダル
    const [open, setOpen] = useState(false);

    // 共通ダイアログ表示
    const [cmnIdChkDlgisOpen, setCmnIdChkDlgisOpen] = useState(false)
    const [cmnMsgDlgisOpen, setCmnMsgDlgisOpen] = useState(false)

    // 修正前情報
    const [beforeUser, setBeforeUser] = useState({});
    
    // 03_独自関数(eventHandlerなど)

    // 新規登録ボタン
    const handleNewUserButton = () =>{
        setUser({...User, user_id:null});
        setCmnIdChkDlgisOpen(true);
    }

    // ID入力ダイアログ閉じ処理
    const handleCmnIdChkDlgisClose = () =>{
        setUser({...User, user_id:null});
        setCmnIdChkDlgisOpen(false);
    }
    

    // 新規ユーザ登録処理
    const createNewUser = ()=>{

        setCmnIdChkDlgisOpen(false);
        if(!User?.user_id) return
        
        const today = new Date();

        const query = new URLSearchParams({user_id: User.user_id});
        fetch(`/api/select/user?${query}`)
          .then((res) => res.json())
          .then((data) => {
            if(!data){

                const tmp_user = {
                    ...User,
                    pw: User.user_id,
                    first_name: "",
                    second_name: "",
                    first_kana_name: "",
                    second_kana_name: "",
                    authority:"10",
                    entrer_date: commonFnc.formatDate(today),
                    retire_date: "",
                    employment_status_cd:"1",
                    work_style_cd:"101",
                    organization_cd:Department_mst?.highly_department_cd,
                    department_cd:Department_mst?.department_cd,
                    first_approval_userid: Department_mst.first_approval_userid,
                    second_approval_userid:  Department_mst.second_approval_userid,
                    enable_public_holiday_days: 0,
                    enable_paid_holiday_days: 0,
                    if_cd:User.user_id,
                    start_paid_holiday_add_day: commonFnc.formatDate(today),
                    fixed_overtime:0,
                    public_holiday_type_cd:public_holiday_type_msts[0].public_holiday_type_cd,
                    idm:"",
                    csv_seq:"",
                    notes:"",
                    notes2:"",
                    notes3:"",
                }

                setUser(tmp_user);
                setBeforeUser(tmp_user);
                setOpen(true);
            }else{
                // IDが取得出来きた時の処理
                // 既に登録されています。
                setCmnMsgDlgisOpen(true);
            }
          });
    }

    // ユーザ編集：ダブルクリック
    const handleEditUserButton = async (row) => {
        
        if(!row.user_id) return;

        const query = new URLSearchParams({user_id: row.user_id});
        const res = await fetch(`/api/select/user?${query}`);
        const data = await res.json();
        setUser(data);
        setBeforeUser(data);
        setOpen(true);
    }
    
    
    return (
        <GenericTemplate title="ユーザマスタ管理"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        <DepMstMenberGrid
            Users={Users}
            setUsers={setUsers}
            Department_msts={Department_msts}
            public_holiday_type_msts={public_holiday_type_msts}
            handleEditUserButton={handleEditUserButton}
            setUser={setUser}
            handleNewUserButton={handleNewUserButton}
            setShiftStartDay={setShiftStartDay}
            setDisplay_user_info={setDisplay_user_info}
        />

        <DepMstMemberEditModal

            Login_user_info={Login_user_info}
            User={User}
            setUser={setUser}
            Department_msts={Department_msts}
            open={open}
            setOpen={setOpen}
            beforeUser={beforeUser}
            setUsers={setUsers}
            public_holiday_type_msts={public_holiday_type_msts}
            setPublic_holiday_type_msts={setPublic_holiday_type_msts}

        />

        <CommonIdCheckDialog

            msg={"登録するユーザのIDを入力してください。"}
            isOpen={cmnIdChkDlgisOpen}
            doYes={createNewUser}
            doNo={() => {handleCmnIdChkDlgisClose()}}
            setObj={setUser}
            Obj={User}
            columnName={"user_id"}

        />
        
        <CommonMsgDialog

            msg={"既に登録されています。"}
            isOpen={cmnMsgDlgisOpen}
            doYes={() => {setCmnMsgDlgisOpen(false)}}
        />
        

        </GenericTemplate>
    );
}

export default Mst_UserMstPage;
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import CommonDialog from "../common/CommonDialog";
import CommonIdCheckDialog from "../common/CommonIdCheckDialog";
import CommonMsgDialog from "../common/CommonMsgDialog"
import DepMstDepartmentEditModal from "../conponents/DepMstDepartmentEditModal";
import DepMstList from "../conponents/DepMstList";
import DepMstMemberEditModal from "../conponents/DepMstMemberEditModal";
import DepMstMemberList from "../conponents/DepMstMemberList";
import GenericTemplate from "../templates/GenericTemplate";
import { TextField } from "@mui/material";
import * as commonFnc from '../common/commonFnc';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };




// name: Mst_departmentMstPage
// summary: 部署マスタ編集画面
// prop1: Users ユーザ情報
// prop2: setUsers ユーザ情報のセッター
// prop3: Department_msts 部署情報
const Mst_departmentMstPage = (props) =>{
    
    // 01_props
    const {setLogin_user_id, setKintai_bscs, Login_user_info, setDisplay_user_info, Users, setUsers, Department_msts} = props;
    
    // 02_useState
    const [Department_mst, setDepartment_mst] = useState({
        department_cd: "",
        name: "",
        highly_department_cd: "",
        department_type: 1,
        first_approval_userid:"",
        second_approval_userid:"",
        use_start_day: "",
        use_end_day: ""
    })
    
    const [public_holiday_type_msts, setPublic_holiday_type_msts] = useState([]);
    useEffect(async ()=>{
        try {
            const res = await fetch(`/api/select/public_holiday_type_msts`);
            const data = await res.json();
            setPublic_holiday_type_msts(data);

        } catch (error) {
            console.error(error);
        }
    },[])
    
    // 新規作成用
    const [User, setUser] = useState({
        user_id: "",
        pw: "",
        first_name: "",
        second_name: "",
        first_kana_name: "",
        second_kana_name: "",
        authority:10,
        entrer_date: "19800701",
        retire_date: "19800701",
        employment_status_cd:"1",
        work_style_cd:"1",
        organization_cd:"999",
        department_cd:"999",
        first_approval_userid:"",
        second_approval_userid:"",
        enable_public_holiday_days: 10,
        enable_paid_holiday_days: 10,
        if_cd:"1",
        start_paid_holiday_add_day:"",
        notes:"",
        notes2:"",
        notes3:"",
    })

    // 新規作成モーダル
    const [open, setOpen] = useState(false);
    const [depModalopen, setDepModalopen] = useState(false);

    // 共通ダイアログ表示
    const [cmnDlgisOpen, setCmnDlgisOpen] = useState(false)
    const [cmnIdChkDlgisOpen, setCmnIdChkDlgisOpen] = useState(false)
    const [cmnDepIdChkDlgisOpen, setCmnDepIdChkDlgisOpen] = useState(false)
    const [cmnDlgDeptisOpen, setCmnDlgDeptisOpen] = useState(false)
    const [cmnMsgDlgisOpen, setCmnMsgDlgisOpen] = useState(false)

    // 修正前情報
    const [beforeUser, setBeforeUser] = useState({});
    const [beforeDept, setBeforeDept] = useState({});

    // 検索用ユーザID
    const [targetUserId, setTargetUserId] = useState("");

    
    // 03_独自関数(eventHandlerなど)
    const handleNewDepartmentButton = () =>{
        
        setDepartment_mst({...Department_mst, department_cd:null});
        setCmnDepIdChkDlgisOpen(true);

    }
    const createNewDepartment = () =>{
        
        setCmnDepIdChkDlgisOpen(false);
        if(!Department_mst.department_cd) return

        const query = new URLSearchParams({department_cd: Department_mst.department_cd});
        fetch(`/api/select/department_mst?${query}`)
          .then((res) => res.json())
          .then((data) => {
            if(!data){
                const tmp_dept = {
                    ...Department_mst,
                    department_cd: Department_mst.department_cd,
                    name: "",
                    highly_department_cd: "",
                    department_type: 1,
                    first_approval_userid: "",
                    second_approval_userid:  "",
                    use_start_day: new Date(),
                    use_end_day: "",
                    if_cd:Department_mst.department_cd
                }

                setDepartment_mst(tmp_dept);
                setBeforeDept(tmp_dept);
                setDepModalopen(true);
            }else{
                // IDが取得出来きた時の処理
                // 既に登録されています。
                setCmnMsgDlgisOpen(true);
            }
        });
    }

    const handleEditDepartmentButton =() => {
        if(!Department_mst.department_cd) return;
        setBeforeDept(Department_mst)
        setDepModalopen(true);
    }
    const handleNewUserButton = () =>{

        setUser({...User, user_id:null});
        setCmnIdChkDlgisOpen(true);

    }
    const handleCmnIdChkDlgisClose = () =>{
        setUser({...User, user_id:null});
        setCmnIdChkDlgisOpen(false);
    }
    const handleCmnDepIdChkDlgisClose = () =>{
        setDepartment_mst({...Department_mst, department_cd:null});
        setCmnDepIdChkDlgisOpen(false);
    }

    const createNewUser = ()=>{

        setCmnIdChkDlgisOpen(false);
        if(!User.user_id) return
        
        const today = new Date();

        const query = new URLSearchParams({user_id: User.user_id});
        fetch(`/api/select/user?${query}`)
          .then((res) => res.json())
          .then((data) => {
            if(!data){

                const tmp_user = {
                    ...User,
                    pw: User.user_id,
                    first_name: "",
                    second_name: "",
                    first_kana_name: "",
                    second_kana_name: "",
                    authority:"10",
                    entrer_date: commonFnc.formatDate(today),
                    retire_date: "",
                    employment_status_cd:"1",
                    work_style_cd:"101",
                    organization_cd:Department_mst?.highly_department_cd,
                    department_cd:Department_mst?.department_cd,
                    first_approval_userid: Department_mst.first_approval_userid,
                    second_approval_userid:  Department_mst.second_approval_userid,
                    enable_public_holiday_days: 0,
                    enable_paid_holiday_days: 0,
                    if_cd:User.user_id,
                    start_paid_holiday_add_day: commonFnc.formatDate(today),
                    fixed_overtime:0,
                    public_holiday_type_cd:public_holiday_type_msts[0].public_holiday_type_cd,
                    idm:"",
                    csv_seq:"",
                    notes:"",
                    notes2:"",
                    notes3:"",
                }

                setUser(tmp_user);
                setBeforeUser(tmp_user);
                setOpen(true);
            }else{
                // IDが取得出来きた時の処理
                // 既に登録されています。
                setCmnMsgDlgisOpen(true);
            }
          });
    }

    const handleEditUserButton = () => {
        if(!User.user_id) return;
        setBeforeUser(User);
        setOpen(true);
    }
    
    // 削除ボタン押下時確認
    const handleDialogYes = () => {

        const bodyParam = new URLSearchParams({user_id: String(User.user_id)});
        const params = {method : "POST", body : bodyParam};
        
        fetch(`/api/delete/user`, params);
        setCmnDlgisOpen(false);
    }

    // 部署削除ボタン押下時確認
    const handleDepDelDialogYes = () => {

        const bodyParam = new URLSearchParams({department_cd: String(Department_mst.department_cd)});
        const params = {method : "POST", body : bodyParam};
        
        fetch(`/api/delete/department_mst`, params);
        setCmnDlgDeptisOpen(false);
    }

    // 検索ボタン押下
    const handleSearchButtonClick = () => {
        const tmpUser = Users.find(p=>p.user_id === targetUserId);
        if(tmpUser){
            setBeforeUser(tmpUser);
            setUser(tmpUser);
            setOpen(true);

            const query = new URLSearchParams({department_cd: tmpUser.department_cd});
            fetch(`/api/select/department_mst?${query}`)
            .then((res) => res.json())
            .then((data) => setDepartment_mst(data)
            );

        }
        
    }
    
    return (
        <GenericTemplate title="部署マスタ管理"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        {!Department_mst || !Login_user_info ? <div class="loader">Loading...</div> : 
            <div className="shiftMstPage_wrapper">
                <div className="shiftMstPage_content">
                    <div className="button_content">
                        <Button className="acction_button" variant="outlined" onClick={handleNewDepartmentButton} style={{marginTop:"7px"}}>新規作成</Button>
                        <Button className="acction_button" variant="outlined" onClick={handleEditDepartmentButton} style={{marginTop:"7px"}}>編集</Button>
                        {/* <Button className="acction_button" variant="outlined" onClick={() => {setCmnDlgDeptisOpen(true)}} style={{marginTop:"7px"}}>削除</Button> */}
                    </div>
                    <DepMstList
                        Department_msts={Department_msts}
                        Department_mst={Department_mst}
                        setDepartment_mst={setDepartment_mst}
                    />
                </div>
                <div className="shiftMstPage_content">
                    <div className="button_content">
                        <Button className="acction_button" variant="outlined" onClick={handleNewUserButton} style={{marginTop:"7px"}}>新規作成</Button>
                        <Button className="acction_button" variant="outlined" onClick={handleEditUserButton} style={{marginTop:"7px"}}>編集</Button>
                        {/* <Button className="acction_button" variant="outlined" onClick={() => {setCmnDlgisOpen(true)}} style={{marginTop:"7px"}}>削除</Button> */}
                    </div>
                    <DepMstMemberList
                        Users={Users}
                        setUser={setUser}
                        Department_mst={Department_mst}
                        handleEditUserButton={handleEditUserButton}
                        targetUserId={targetUserId}
                        setTargetUserId={setTargetUserId}
                    />
                </div>
                <div className="shiftMstPage_content">
                    <div className="button_content">
                        <TextField 
                            variant="outlined" 
                            name="user_id" 
                            sx={{'& .MuiInputBase-root': { height: '50px', marginTop:'7px' },}}
                            value={targetUserId} 
                            onChange={(e)=>setTargetUserId(e.target.value)} 
                            label="ユーザID"
                        />
                        <Button className="acction_button" variant="outlined" onClick={handleSearchButtonClick} style={{marginTop:"7px"}}>検索</Button>
                        {/* <Button className="acction_button" variant="outlined" onClick={() => {setCmnDlgisOpen(true)}} style={{marginTop:"7px"}}>削除</Button> */}
                    </div>
                    
                </div>
            </div>
        }
        <DepMstMemberEditModal

            Login_user_info={Login_user_info}
            User={User}
            setUser={setUser}
            Department_msts={Department_msts}
            open={open}
            setOpen={setOpen}
            beforeUser={beforeUser}
            setUsers={setUsers}
            public_holiday_type_msts={public_holiday_type_msts}
            setPublic_holiday_type_msts={setPublic_holiday_type_msts}

        />
        
        <CommonDialog

            msg={"削除しますか?"}
            isOpen={cmnDlgisOpen}
            doYes={handleDialogYes}
            doNo={() => {setCmnDlgisOpen(false)}}

        />

        
        <CommonDialog

            msg={"対象の部署を削除しますか?"}
            isOpen={cmnDlgDeptisOpen}
            doYes={handleDepDelDialogYes}
            doNo={() => {setCmnDlgDeptisOpen(false)}}

        />

        <DepMstDepartmentEditModal

            Department_mst={Department_mst}
            setDepartment_mst={setDepartment_mst}
            Department_msts={Department_msts}
            depModalopen={depModalopen}
            setDepModalopen={setDepModalopen}
            beforeDept={beforeDept}
        />
            
        <CommonIdCheckDialog

            msg={"登録するユーザのIDを入力してください。"}
            isOpen={cmnIdChkDlgisOpen}
            doYes={createNewUser}
            doNo={() => {handleCmnIdChkDlgisClose()}}
            setObj={setUser}
            Obj={User}
            columnName={"user_id"}

        />

        <CommonIdCheckDialog

            msg={"登録する部署のCDを入力してください。"}
            isOpen={cmnDepIdChkDlgisOpen}
            doYes={createNewDepartment}
            doNo={() => {handleCmnDepIdChkDlgisClose()}}
            setObj={setDepartment_mst}
            Obj={Department_mst}
            columnName={"department_cd"}

        />
        
        <CommonMsgDialog

            msg={"既に登録されています。"}
            isOpen={cmnMsgDlgisOpen}
            doYes={() => {setCmnMsgDlgisOpen(false)}}
        />
        

        </GenericTemplate>
    );
}

export default Mst_departmentMstPage;
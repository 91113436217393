import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ApprovalsTableKntaiReturnModal from "../conponents/ApprovalsTableKntaiReturnModal";
import * as commonFnc from '../common/commonFnc';
import * as BL_approval from '../BL/BL_approval';
import {GlobalContext} from '../globalState'
import { Kintai_overtime } from "../classes/Kintai_overtime_class";
import { Box, Checkbox, Tooltip } from "@mui/material";


// name: ApprovalsTable
// summary: 【承認者用画面】承認待ち勤怠の一覧を表示する。
// prop: login_user_id
// prop: Kintai_bsc_this 対象の勤怠情報　Class名と被るため名称にthis
// prop: setKintai_bsc
// prop: Shift_msts
// prop: Department_msts
// prop: Users 
// prop: serchCondition
// prop: setSerchCondition
// prop: approval_kintai_bscs_view 承認一覧に表示する勤怠承認情報(承認者でのfiler後データ)
// prop: setApproval_kintai_bscs_view
// prop: setDisplay_user_info

const ApprovalsTable = (props) =>{
    // 01_props
    const {login_user_id,
            Kintai_bsc_this,
            setKintai_bsc,
            Shift_msts,
            Department_msts, 
            Users, 
            serchCondition,
            setSerchCondition, 
            setDisplay_user_info, 
            setRequest_overtimes, 
            setRequest_paid_holiday, 
            setAppcdCounter,
            filterApprovalStatusCd,
            viewIndex,
            APPROVAL_TABLE_VIEW_NUM,
            approval_kintai_bscs,
            setApproval_kintai_bscs,
            getApproval_kintai_bsc,
            loading,
            setLoading,
            setShiftStartDay
        } = props;

    // 02_useState
    const approvalTexts = useContext(GlobalContext).approvalTexts;

    const Env_msts = useContext(GlobalContext).Env_msts;
    const count1 = Env_msts.find(p=>p.env_key==="COUNT1");
    const count2 = Env_msts.find(p=>p.env_key==="COUNT2");
    const count3 = Env_msts.find(p=>p.env_key==="COUNT3");
    const count4 = Env_msts.find(p=>p.env_key==="COUNT4");
    const count5 = Env_msts.find(p=>p.env_key==="COUNT5");

    const history = useHistory();
    const [approval_kintai_bscs_view, setApproval_kintai_bscs_view] = useState([]);
    const [approvalReturnModalOpen, setApprovalReturnModalOpen] = useState(false);
    
    const [hidden_request_overtime_notes, setHidden_Request_overtime_notes] = useState(true);

    var shiftHash = {};
    Shift_msts.map((Shift_mst) => (
        shiftHash[Shift_mst.shift_type_cd] = Shift_mst.shift_name
    ));

    
    var shiftColorHash = {};
    Shift_msts.map((Shift_mst) => (
        shiftColorHash[Shift_mst.shift_type_cd] = {
            backgroundcolor: Shift_mst.backgroundcolor,
            textcolor: Shift_mst.textcolor
        }
    ));

    var deptHash = {};
    Department_msts.map((dept) => (
        deptHash[dept.department_cd] = dept.name
    ));

    var userNameHash = {};
    Users.map((user) => (
        userNameHash[user.user_id] = user.first_name + " " + user.second_name
    ));

    // 03_独自関数(eventHandlerなど)

    // 承認情報変更：承認時等
    useEffect(()=>{
        reloadApprovalView();
    },[approval_kintai_bscs, filterApprovalStatusCd])

    const reloadApprovalView = async ()=>{

        if(approval_kintai_bscs.length > 0){

            let tmp_approval_kintai_bscs_view_filter = approval_kintai_bscs;
            
            if(serchCondition.target_deptgr_cd.length > 1){
                tmp_approval_kintai_bscs_view_filter = tmp_approval_kintai_bscs_view_filter.filter(item => item.user_info[0]?.organization_cd === serchCondition.target_deptgr_cd);
            }
            if(serchCondition.target_dept_cd.length > 1){
                tmp_approval_kintai_bscs_view_filter = tmp_approval_kintai_bscs_view_filter.filter(item => item.user_info[0]?.department_cd === serchCondition.target_dept_cd);
            }
            if(serchCondition.target_user_id.length > 0){
                tmp_approval_kintai_bscs_view_filter = tmp_approval_kintai_bscs_view_filter.filter(item => item.user_id === serchCondition.target_user_id);
            }
            if(serchCondition.target_user_name.length > 0){
                tmp_approval_kintai_bscs_view_filter = tmp_approval_kintai_bscs_view_filter.filter(item => userNameHash[item.user_id]?.indexOf(serchCondition.target_user_name)>-1);
            }
            if(serchCondition.target_shift_type_cd.length > 0){
                tmp_approval_kintai_bscs_view_filter = tmp_approval_kintai_bscs_view_filter.filter(item => item.shift_type_cd?.indexOf(serchCondition.target_shift_type_cd)>-1);
            }
            if(serchCondition.exist_over_time === 1){
                // 残業なしのみ
                tmp_approval_kintai_bscs_view_filter = tmp_approval_kintai_bscs_view_filter.filter(item => (item.over_time===null && item.midnight_over_time===null) || (item.over_time===undefined && item.midnight_over_time===undefined));
            }else if(serchCondition.exist_over_time === 2){
                // 残業ありのみ
                tmp_approval_kintai_bscs_view_filter = tmp_approval_kintai_bscs_view_filter.filter(item => item.over_time>0 || item.midnight_over_time>0);
            }

            // 承認待ち件数を表示
            setAppcdCounter({
                count90: tmp_approval_kintai_bscs_view_filter.filter(item => item.approval_status_cd === "90").length,
                count80: tmp_approval_kintai_bscs_view_filter.filter(item => item.approval_status_cd === "80").length,
                count30: tmp_approval_kintai_bscs_view_filter.filter(item => item.approval_status_cd === "30").length,
                count20: tmp_approval_kintai_bscs_view_filter.filter(item => item.approval_status_cd === "20").length,
                count10: tmp_approval_kintai_bscs_view_filter.filter(item => item.approval_status_cd === "10").length,
            })

            tmp_approval_kintai_bscs_view_filter.forEach(obj => {
                obj.isExcludedForAllApproval = false; // 一括承認除外フラグを追加
            });

            setApproval_kintai_bscs_view(tmp_approval_kintai_bscs_view_filter);
                
        }else{
            setAppcdCounter({
                count90: 0,
                count80: 0,
                count30: 0,
                count20: 0,
                count10: 0,
            });
            setApproval_kintai_bscs_view([]);

        }

        
    }


    const moveRequestKintaiPage = ((Kintai_bsc) =>{
        setKintai_bsc(Kintai_bsc);

        // fechで取得するように変更する
        const query = new URLSearchParams({login_user_id: Kintai_bsc.user_id, date: commonFnc.formatDate(Kintai_bsc.date)});
        fetch(`/api/select/request_overtime?${query}`)
        .then((res) => res.json())
        .then((data) => {
            if(!data){
                setRequest_overtimes([new Kintai_overtime(Kintai_bsc)])
            }else{
                setRequest_overtimes(data)
            }
        });

        const queryRequest_paid_holiday = new URLSearchParams({login_user_id: Kintai_bsc.user_id, date: commonFnc.formatDate(Kintai_bsc.date)});
        fetch(`/api/select/request_paid_holiday?${queryRequest_paid_holiday}`)
        .then((res) => res.json())
        .then((data) => {
            if(!data){
                setRequest_paid_holiday({
                    request_paid_holiday_reason: "",
                })
            }else{
                setRequest_paid_holiday(data)
            }
        });

        let tmp_user = Users.find(item => item.user_id === Kintai_bsc.user_id);
        setDisplay_user_info(tmp_user);

        history.push('/RequestKintai');
    })

    // 承認ボタンクリック
    const handlerRequestButtonClick = async (target_kintai_bsc) =>{

        if(target_kintai_bsc.second_approval_userid === login_user_id 
            &&( target_kintai_bsc.approval_status_cd === "20"
            ||  target_kintai_bsc.approval_status_cd === "80")){

                var result = window.confirm('一次承認待ちの勤務です。繰り上げ承認しますか。');
                if( !result ) return;

        }
        await BL_approval.requestApproval("approval_kintai_bsc", target_kintai_bsc, Department_msts, setApproval_kintai_bscs, login_user_id);

        getApproval_kintai_bsc();
        
    };
    // 差戻ボタンクリック
    const handlerReturnButtonClick = (async(target_kintai_bsc) =>{
        await setKintai_bsc(target_kintai_bsc);
        setApprovalReturnModalOpen(true);
    });

    // 取り消しボタンクリック
    const handlerCancelButtonClick = async (target_kintai_bsc) =>{
            await BL_approval.requestCancel("approval_kintai_bsc", target_kintai_bsc, Department_msts, setApproval_kintai_bscs, login_user_id);
            getApproval_kintai_bsc();
    };
    
    // 名前ボタンクリック
    const handlerNameButtonClick = (user_name)=>{
        setSerchCondition({
            ...serchCondition,
            target_date_from : document.getElementById("target_date_from").value,
            target_date_to : document.getElementById("target_date_to").value,
            target_deptgr_cd : document.getElementsByName("target_deptgr_cd")[0].value,
            target_dept_cd : document.getElementsByName("target_dept_cd")[0].value,
            target_user_id : document.getElementById("target_user_id").value,
            target_user_name : user_name, 
        });
        getApproval_kintai_bsc();
        
    }


    // 進捗管理用
    const [progress, setProgress] = useState(0);
    const [allNum, setAllNum] = useState(0);
    const progressInitial = (allNum) =>{
        setAllNum(allNum);
    }
    const progressCountUp = (completedNum) =>{
        setProgress(completedNum);
    }
    const progressClear = (completedNum) =>{
        setProgress(0);
        setAllNum(0)
    }

    // 一括承認ボタンクリック
    const handlerAllRequestButtonClick = async () =>{

        var msg = "一括で承認しますか。完了までしばらくお待ちください。";
        if(existOtherApprovalUser(approval_kintai_bscs_view,login_user_id)){
            msg = "次回承認者があなたでは無い勤務はスキップします。" + msg;
        }

        var result = window.confirm(msg);
        if( result ) {

            const target_approval_kintai_bscs = approval_kintai_bscs_view
            .filter(p=>p.approval_status_cd===filterApprovalStatusCd)
            .slice(viewIndex, viewIndex+APPROVAL_TABLE_VIEW_NUM)
            .filter(p=>p.isExcludedForAllApproval===false); // 一括承認対象除外フラグがfalse

            // 進捗管理を開始
            let completed = 1;
            progressInitial(target_approval_kintai_bscs.length);

            const promises = target_approval_kintai_bscs.map(async (approval_kintai_bsc_view) => {
                if(approval_kintai_bsc_view.next_approval_userid === login_user_id){
                    await BL_approval.requestApproval("approval_kintai_bsc", approval_kintai_bsc_view, Department_msts, setApproval_kintai_bscs, login_user_id);
                    progressCountUp(completed += 1);
                }
            });
            
            await Promise.all(promises);
            getApproval_kintai_bsc();
            window.alert("一括承認が完了しました。");
            progressClear();

        }
    };
    
    // 一括取消ボタンクリック
    const handlerAllCancelButtonClick = async () =>{

        var msg = "前回承認者があなたでは無い勤務はスキップします。\n一括取消しますか。完了までしばらくお待ちください。";

        var result = window.confirm(msg);
        if( result ) {

            const target_approval_kintai_bscs = approval_kintai_bscs_view
            .filter(p=>p.approval_status_cd===filterApprovalStatusCd)
            .slice(viewIndex, viewIndex+APPROVAL_TABLE_VIEW_NUM)
            .filter(p=>p.isExcludedForAllApproval===false);        // 一括承認対象除外フラグがfalse
            
            // 進捗管理を開始
            let completed = 1;
            progressInitial(target_approval_kintai_bscs.length);

            const promises = target_approval_kintai_bscs.map(async (approval_kintai_bsc_view) => {
                if(approval_kintai_bsc_view.last_approval_userid === login_user_id){
                    await BL_approval.requestCancel("approval_kintai_bsc", approval_kintai_bsc_view, Department_msts, setApproval_kintai_bscs, login_user_id);
                    progressCountUp(completed += 1);
                }
            });
            
            await Promise.all(promises);
            getApproval_kintai_bsc();
            window.alert("一括取消が完了しました。");
            progressClear();

        }
    };

    const handleChangeHiddenFlg = ((setter, flg)=>{
        setter(!flg);
    })

    // 一括承認除外フラグの切替関数

    const handleChangeIsExcluded = (event, _id) => {
        const { name, checked } = event.target; // イベントから名前とチェック状態を取得
      
        setApproval_kintai_bscs_view((prevState) => {
          // 状態を更新
          const updatedKintai = prevState.map((kintai) => {
            if (kintai._id === _id) {
              return {
                ...kintai, // 元のオブジェクトをコピー
                [name]: checked, // 指定のプロパティを更新
              };
            }
            return kintai; // 一致しない場合はそのまま返す
          });
      
          return updatedKintai; // 更新後の配列を返す
        });
      };


    //  承認対象の勤怠の中に自分以外の承認者が含まれているか確認する。
    const existOtherApprovalUser = ((approval_kintai_bscs_view, user_id) => {

        var count = 0;
        approval_kintai_bscs_view
        .filter(p=>p.approval_status_cd===filterApprovalStatusCd)
        .slice(viewIndex, viewIndex+APPROVAL_TABLE_VIEW_NUM)
        .map((Kintai_bsc)=>{
            if(Kintai_bsc.next_approval_userid !== user_id){
                count++;
            }
        })

        return count > 0
    })

    const moveCalender = async (Kintai_bsc) => {
        
        // 勤怠一覧を初期化。遷移先で再取得
        // setKintai_bscs([]);   

        let tmp_user = Users.find(item => item.user_id === Kintai_bsc.user_id);
        setDisplay_user_info(tmp_user);

        var d = new Date(serchCondition.target_date_from);
        setShiftStartDay(d);    

        history.push('/Kintai');

    }


    const tableRowStyle = (isExcludedForAllApproval) =>{
        return (
            {
                backgroundColor: isExcludedForAllApproval?'lightGray':'snow'
            }
        )
    }
    const style = {
        tableRow:tableRowStyle,
        tableHeaderCellBase:{
            fontSize:'0.8rem',
            padding:'3px',
            "& .MuiTableCell-root": { padding: "8px" }
        },
        tableCellNote:{
            maxWidth: '100px', // 必要に応じて指定
            whiteSpace: 'nowrap', // テキストを1行に制限
            overflow: 'hidden', // 溢れた部分を隠す
            textOverflow: 'ellipsis', // 溢れた部分に「…」を表示
            position: 'relative', // 必要に応じて追加
            padding:'10px',
        },
        linkButton:{
            fontSize:'0.8rem',
            color:'blue'
        },
        approvalButton:{
            fontSize:'0.8rem',
            padding:'1px'
        },
        allApprovalButton:{
            fontSize:'0.6rem',
            padding:'1px',
            margin:'1px'
        }
    }

    return (
        <div className={progress?"loading-wrapper":false}>
            {progress?
            <div class="loading-center">
                <div class="loader">Loading...</div>
                <div>処理済み: {`${progress}/${allNum}`}</div>
            </div>:<></>
            }
            {loading ? <div class="loader">Loading...</div> :
            <TableContainer component={Paper} style={{ maxHeight: '65vh'}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{...style.tableHeaderCellBase, minWidth:100}} >日付</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase, minWidth:100}} >施設名</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase, minWidth:100}} >部署名</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >職員番号</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase, minWidth:100}} >氏名</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase, minWidth:80}} >シフト</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >開始時間</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >終了時間</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >時間</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >休憩</TableCell>
                            <TableCell 
                                align="center" 
                                style={{...style.tableHeaderCellBase}} 
                                onClick={()=>handleChangeHiddenFlg(setHidden_Request_overtime_notes, hidden_request_overtime_notes)}
                                >
                                <span className="link">時間外</span>
                            </TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >深夜</TableCell>
                            {hidden_request_overtime_notes ? "" :
                                <TableCell>残業理由</TableCell>
                            }
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >遅刻</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >早退</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >外出</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}} >業務外</TableCell>
                            {count1?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{count1?.env_value.substr( 0, 4 )}</TableCell>}
                            {count2?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{count2?.env_value.substr( 0, 4 )}</TableCell>}
                            {count3?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{count3?.env_value.substr( 0, 4 )}</TableCell>}
                            {count4?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{count4?.env_value.substr( 0, 4 )}</TableCell>}
                            {count5?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{count5?.env_value.substr( 0, 4 )}</TableCell>}
                            <TableCell align="center" style={{...style.tableHeaderCellBase}}>備考</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase, minWidth:100}}>次回承認</TableCell>
                            <TableCell align="center" style={{...style.tableHeaderCellBase}}>一括除外</TableCell>
                            <TableCell style={{...style.tableHeaderCellBase}} >
                                <Box sx={{width:'150px'}}>
                                    <div>
                                    <Button 
                                        style={style.allApprovalButton}
                                        variant="outlined" 
                                        color="primary" 
                                        onClick={()=>handlerAllRequestButtonClick()}>
                                        一括承認
                                    </Button>
                                    </div>
                                    <div>
                                    <Button 
                                        style={style.allApprovalButton}
                                        variant="outlined" 
                                        color="primary" 
                                        onClick={()=>handlerAllCancelButtonClick()}>
                                        一括取消
                                    </Button>
                                    </div>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {approval_kintai_bscs_view.filter(p=>p.approval_status_cd===filterApprovalStatusCd).slice(viewIndex, viewIndex+APPROVAL_TABLE_VIEW_NUM).map((Kintai_bsc, index)=>(
                            <TableRow key={index} style={style.tableRow(Kintai_bsc.isExcludedForAllApproval)}>
                                <TableCell style={{...style.tableHeaderCellBase}} component="th" scope="row">
                                    <Button variant="outlined" color="primary" onClick={(e)=>moveRequestKintaiPage(Kintai_bsc)}
                                        value={commonFnc.ConvertJST(Kintai_bsc.date).split(' ')[0]}>
                                        {commonFnc.ConvertJST(Kintai_bsc.date).split(' ')[0]
                                        + "(" + [ "日", "月", "火", "水", "木", "金", "土" ][(new Date(Kintai_bsc.date)).getDay()] + ")"}
                                    </Button>
                                </TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{deptHash[Kintai_bsc.user_info[0]?.organization_cd]}</TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{deptHash[Kintai_bsc.user_info[0]?.department_cd]}</TableCell>
                                {/* <TableCell align="center">{Kintai_bsc.user_id}</TableCell> */}
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}><Button style={style.linkButton} onClick={()=>moveCalender(Kintai_bsc)}>{Kintai_bsc.user_id}</Button></TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}><Button style={style.linkButton} onClick={()=>handlerNameButtonClick(userNameHash[Kintai_bsc.user_id])}>{userNameHash[Kintai_bsc.user_id]}</Button></TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>
                                    <div 
                                        className={"shift_type"} 
                                        style={{backgroundColor: Kintai_bsc.shift_type_cd ? shiftColorHash[Kintai_bsc.shift_type_cd]?.backgroundcolor:"transparent" ,color: Kintai_bsc.shift_type_cd ? shiftColorHash[Kintai_bsc.shift_type_cd]?.textcolor:""}}>
                                        {shiftHash[Kintai_bsc.shift_type_cd]?shiftHash[Kintai_bsc.shift_type_cd]:"-"}
                                    </div>
                                    {Kintai_bsc.harf_holiday_shift_type_cd?
                                    <div className={"shift_type"} style={{backgroundColor: Kintai_bsc.harf_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.harf_holiday_shift_type_cd]?.backgroundcolor:"" ,color: Kintai_bsc.harf_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.harf_holiday_shift_type_cd]?.textcolor:""}}>
                                        {shiftHash[Kintai_bsc.harf_holiday_shift_type_cd]}
                                    </div>
                                    :""}
                                    {Kintai_bsc.paid_holiday_shift_type_cd?
                                    <div className={"shift_type"} style={{backgroundColor: Kintai_bsc.paid_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.paid_holiday_shift_type_cd]?.backgroundcolor:"" ,color: Kintai_bsc.paid_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.paid_holiday_shift_type_cd]?.textcolor:""}}>
                                        {shiftHash[Kintai_bsc.paid_holiday_shift_type_cd]}
                                    </div>
                                    :""}
                                </TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>
                                    <span>{commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_from)}</span>
                                    <span class="helper_clockin_time"><br/>{(!Kintai_bsc.clockin_info.find(p=>p.clockin_type_cd==="10")?.maxTime ? "": Kintai_bsc.clockin_info.find(p=>p.clockin_type_cd==="10")?.maxTime)}</span>
                                </TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>
                                    <span>{commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_to)}</span>
                                    <span class="helper_clockin_time"><br/>{(!Kintai_bsc.clockin_info.find(p=>p.clockin_type_cd==="90")?.maxTime ? "": Kintai_bsc.clockin_info.find(p=>p.clockin_type_cd==="90")?.maxTime)}</span>
                                </TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.work_time ? "" : commonFnc.getHHmm(Kintai_bsc.work_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.rest_time ? "" : commonFnc.getHHmm(Kintai_bsc.rest_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.over_time ? "" : commonFnc.getHHmm(Kintai_bsc.over_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.midnight_over_time ? "" : commonFnc.getHHmm(Kintai_bsc.midnight_over_time)}</TableCell>
                                {hidden_request_overtime_notes ? "" :
                                    <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.user_id ? "" : Kintai_bsc.over_time_info[0]?.reason}</TableCell>
                                }
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.behind_time ? "" : commonFnc.getHHmm(Kintai_bsc.behind_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.early_time ? "" : commonFnc.getHHmm(Kintai_bsc.early_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.out_time ? "" : commonFnc.getHHmm(Kintai_bsc.out_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.training_time ? "" : commonFnc.getHHmm(Kintai_bsc.training_time)}</TableCell>
                                {count1?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{Kintai_bsc.count1 ? count1?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                {count2?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{Kintai_bsc.count2 ? count2?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                {count3?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{Kintai_bsc.count3 ? count3?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                {count4?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{Kintai_bsc.count4 ? count4?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                {count5?.log_del_flg ? "" : <TableCell align="center" style={{...style.tableHeaderCellBase}}>{Kintai_bsc.count5 ? count5?.env_value.substr( 0, 1 ) : ""}</TableCell>}
                                <TableCell align="center" style={{...style.tableHeaderCellBase, ...style.tableCellNote}}>
                                    <Tooltip title={Kintai_bsc.kintai_note} placement="top">
                                        {!Kintai_bsc.kintai_note ? "" : Kintai_bsc.kintai_note}
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>{!Kintai_bsc.next_approval_userid ? "" : userNameHash[Kintai_bsc.next_approval_userid]}</TableCell>
                                <TableCell align="center" style={{...style.tableHeaderCellBase}}>
                                    <Checkbox
                                        size="small"
                                        key={`checkbox-${index}`} // 一意な key を設定
                                        name="isExcludedForAllApproval"
                                        checked={Kintai_bsc.isExcludedForAllApproval}
                                        onChange={(e)=>handleChangeIsExcluded(e, Kintai_bsc._id)}
                                    />
                                    <Box style={{fontSize:'0.6rem'}}>{Kintai_bsc.isExcludedForAllApproval?'一括除外':''}</Box>
                                </TableCell>
                                {Kintai_bsc.last_approval_userid === login_user_id ?
                                    <TableCell style={{...style.tableHeaderCellBase}}>
                                        <Box sx={{width:'150px'}}>
                                            <Box sx={{margin:'0 auto'}}>
                                                <Button variant="outlined" color="primary" 
                                                    onClick={(e)=>handlerCancelButtonClick(Kintai_bsc)}>
                                                    取消
                                                </Button>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    : 
                                    <TableCell style={{...style.tableHeaderCellBase}}>
                                        <Box sx={{width:'150px'}}>
                                            <Box sx={{margin:'0 auto'}}>
                                                <Button 
                                                    style={style.approvalButton}
                                                    variant="outlined"
                                                    color="primary" 
                                                    disabled={!(Kintai_bsc.next_approval_userid === login_user_id || Kintai_bsc.second_approval_userid === login_user_id)}
                                                    onClick={(e)=>handlerRequestButtonClick(Kintai_bsc)}>
                                                    承認
                                                </Button>
                                            </Box>
                                            <Box sx={{margin:'0 auto'}}>
                                                <Button
                                                    style={style.approvalButton}
                                                    variant="outlined"
                                                    color="primary" 
                                                    disabled={!(Kintai_bsc.next_approval_userid === login_user_id || Kintai_bsc.second_approval_userid === login_user_id)}
                                                    onClick={(e)=>handlerReturnButtonClick(Kintai_bsc)}>
                                                    差戻
                                                </Button>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                        <ApprovalsTableKntaiReturnModal
                
                            Kintai_bsc={Kintai_bsc_this}
                            setKintai_bsc={setKintai_bsc}
                            approvalReturnModalOpen={approvalReturnModalOpen}
                            setApprovalReturnModalOpen={setApprovalReturnModalOpen}
                            Department_msts={Department_msts}
                            setApproval_kintai_bscs={setApproval_kintai_bscs}
                            login_user_id={login_user_id}
                            urlPrefix="approval_kintai_bsc"
                            getApproval_kintai_bsc={getApproval_kintai_bsc}
                        >
                        </ApprovalsTableKntaiReturnModal>
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    );
}
export default ApprovalsTable;
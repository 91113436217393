import { User_log } from "../classes/user_log_class";
import { Kintai_bsc } from "../classes/kintai_bscs_class";

const SHIFT_START_DAY = 1;

/**
 * ユーザログを登録する。
 * @returns {boolean}
 */



/**
 * ユーザログを登録する。
 * @param {string} log_level ログレベル
 * @param {string} login_user_id ログインユーザID
 * @param {string} source_name ファイル名
 * @param {string} page 画面名
 * @param {string} process_name 処理名
 * @param {string} process_type 処理分類：画面遷移、更新処理、追加処理、削除処理
 * @param {string} summary 内容
 * @param {Object} before 更新前
 * @param {Object} after 更新後
 * @returns 
 */
export async function insertUserLog(log_level, login_user_id, source_name, page, process_name, process_type, summary, before, after){

    // const Env_msts = useContext(GlobalContext).Env_msts;
    // const LOG_LEVEL = Env_msts.find(p=>p.env_key === "LOG_LEVEL");
    // const view_log_level_list = LOG_LEVEL.split(",");
    const view_log_level_list = ["ERROR", "WARNING", "INFO", "DEBUG", "TRACE"]

    if(!view_log_level_list.includes(log_level)) return;

    var bodyData = new User_log();
    
    bodyData = {
        ...bodyData,
        log_level:log_level,
        user_id:login_user_id,
        source_name:source_name,
        page:page,
        process_name:process_name,
        process_type:process_type,
        summary:summary,
        before:before,
        after:after,
      }

    const url_user_log = "/api/insert/userlog";
    const request ={
        method: 'POST',
        headers:{'Accept': 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify(bodyData)
    };
    await fetch(url_user_log,request)
    
  }

/**
 * モバイル端末か判定する。
 * @returns {boolean}
 */
export function isMobile(){
    const $ua = navigator.userAgent;
    if($ua.search('mobile') >= 0 || $ua.search('Mobile') >= 0 || $ua.search('tablet') >= 0 || $ua.search('Tablet') >= 0 ) return true;
  }
  
/**
 * 社内アクセスか判定する。
 * @returns {boolean}
 */
export async function isInternalAccess(){
    // サーバーサイドのAPIエンドポイント
    const apiEndpoint = '/api/check-access';

    // メインの処理
        const response = await fetch(apiEndpoint);
        const data = await response.json();
        const isInternalAccess = data.isInternalAccess;
    if (isInternalAccess) {
        // 社内アクセスの場合の処理
        return true;
    } else {
        // 社外アクセスの場合の処理
        return false;
    }

}


/**
 * 曜日を取得する
 * @param {*} date 
 * @returns 
 */
export function getDayOfWeek (date) {
    if(!date) return "";

    try{
        const targetDate = new Date(date);
        const dayArr = [ "日", "月", "火", "水", "木", "金", "土" ]
    
        return dayArr[targetDate.getDay()];
    }catch{
        return "";
    }

}


  
/**
 * 曜日に依ったstyleを返却する。
 * @param {string} dateString - yyyy-mm-dd形式の日付
 * @param {Object} holidays - 祝日データ
 * @returns {Object} - スタイルオブジェクト
 */
export function getStyleForDate(dateString, holidays) {

    const defaultStyle ={ backgroundColor: '#FFFFFF', color: 'black' };

    if(!holidays) return defaultStyle;
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const isHoliday = holidays[dateString] !== undefined;
  
    const weekdayStyles = {
      0: { backgroundColor: 'coral', color: 'snow' },
      1: defaultStyle,
      2: defaultStyle,
      3: defaultStyle,
      4: defaultStyle,
      5: defaultStyle,
      6: { backgroundColor: 'lightsteelblue', color: 'snow' },
    };
  
    if (isHoliday) {
      return { backgroundColor: 'tomato', color: 'snow' };
    }
  
    return weekdayStyles[dayOfWeek];
  }


/**
 * UTC文字列をJST日付に変換する
 * @param {String} utcDateStrig utc日付文字列
 * @returns {String} jst日付文字列
 */
export function ConvertJST(utcDateStrig) {
    try {
        if(!utcDateStrig){
            return "";
        }
    
        return new Date(utcDateStrig).toLocaleString();   
    } catch (error) {
        console.log(error);
        return "";
        
    }

}

// export function ConvertUTC(jstDateStrig){
//     var time = new Date(jstDateStrig);
//     time.setHours(time.getHours() - 9);
//     return time.toLocaleString();
// }

/**
 * 日付を時刻HH:MM形式にフォーマットする。
 * @param {Date} utcDateStrig 
 * @returns {String} HH:MM形式の文字列
 */
export function ConvertJSTHHMM(utcDateStrig) {
    if(!utcDateStrig){
        return "";
    }

    var dt =  new Date(utcDateStrig)

    var h = ('00' + (dt.getHours())).slice(-2)
    var m = ('00' + (dt.getMinutes())).slice(-2)
    
    var dateStr = (h + ':' + m)

    return dateStr;

}


/**
 * 日付をYYYY-MM-DD形式に変換する。
 * @param {Date} tgtDate 基準日
 * @returns {String} YYYY-MM-DD形式の日付文字列
 */
export function formatDate(tgtDate) {
    if(!tgtDate){
        return "";
    }

    var dt = new Date(tgtDate);

    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth()+1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);

    var dateStr = (y + '-' + m + '-' + d)
    
    return dateStr;
    
}

/**
 * HH:MM形式の時刻をミリ秒に変換する。
 * @param {String} tgtTime 時刻
 * @returns {String} dateStr YYYY-MM-DD形式の日付文字列
 */
export function HHMMtoMsec(tgtTime) {
    // if(!tgtTime || !tgtTime.match(/^[0-9]+:[0-9]{2}:[0-9]{2}$/)) return;

    let time = tgtTime.split(":");

    let h = time[0]*60*60*1000;
    let m = time[1]*60*1000;

    return h+m;
    
}

/**
 * 日付をYYYY-MM-DDThh:mm形式に変換する。
 * @param {Date} tgtDate 基準日
 * @returns {String}YYYY-MM-DD形式の日付文字列
 */
export function formatDateTime(tgtDate) {
    if(!tgtDate){
        return "";
    }

    var dt = new Date(tgtDate);

    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth()+1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);

    var h = ('00' + dt.getHours()).slice(-2);
    var mm = ('00' + dt.getMinutes()).slice(-2);

    var dateStr = (y + '-' + m + '-' + d + 'T' + h + ':' + mm);
    
    return dateStr;
    
}

/**
 * 日付をYYYY-MM-DDThh:mm形式に変換する。
 * @param {Date} tgtDate 基準日
 * @returns {String}YYYY-MM-DD hh:mm:ss形式の日付文字列
 */
export function formatTimestamp(tgtDate) {
    if(!tgtDate){
        return "";
    }

    var dt = new Date(tgtDate);

    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth()+1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);

    var h = ('00' + dt.getHours()).slice(-2);
    var mm = ('00' + dt.getMinutes()).slice(-2);
    var ss = ('00' + dt.getSeconds()).slice(-2);

    var dateStr = (y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + ss);
    
    return dateStr;
    
}

/**
 * カレンダー(指定日から1か月)を取得する。
 * @param {Date} tgtDateFrom 基準日
 * @returns {Array<Date>} days 指定日から1か月の日付配列
 */
export function getCalendar(tgtDateFrom) {
    var tgtDateTo = new Date(tgtDateFrom);
    tgtDateTo = tgtDateTo.setMonth(tgtDateTo.getMonth() + 1);
    var termDay = (tgtDateTo - tgtDateFrom) / 86400000;

    var days = [new Date(tgtDateFrom)];
    for (var i=0; i < termDay - 1; i++) {
        days.push(new Date(tgtDateFrom.setDate(tgtDateFrom.getDate() + 1)));
    }

    return days;
}

/**
 * 勤怠カレンダーに表示する情報を成型する。
 * @param {Array<Date>} days 勤怠カレンダーに表示する日付情報
 * @param {Object} kintai_bscs 勤怠入力情報
 * @param {Object} clockin_jarnals_atMonth_atUser 打刻情報
 * @param {Object} user_id 表示対象のユーザID
 * @returns {Array<Date>} newCalender daysとkintai_bscsをマージした配列
 */
export function createCalender(days, kintai_bscs, clockin_jarnals_atMonth_atUser, user_id){
    
    var newCalender = [];
    
    var tmpDays = [];
    days.forEach(date => {
        tmpDays.push(new Kintai_bsc({date:date,user_id:user_id}));
    });

    tmpDays.forEach((item)=>{
        newCalender.push(item);
        var tmpDays_date = formatDate(item.date);

        kintai_bscs.forEach((kintai_bsc)=>{

            const clockin_time_from = clockin_jarnals_atMonth_atUser.find(p=>p._id.user_id === kintai_bsc.user_id && p._id.shift_date === formatDate(item.date) && p._id.clockin_type_cd === "10" )?.max_time;
            const clockin_time_to = clockin_jarnals_atMonth_atUser.find(p=>p._id.user_id === kintai_bsc.user_id && p._id.shift_date === formatDate(item.date) && p._id.clockin_type_cd === "90" )?.max_time;
            
            const kintai_bsc_date = formatDate(kintai_bsc.date);
            if(tmpDays_date === kintai_bsc_date){
                kintai_bsc["clockin_time_from"] = clockin_time_from;
                kintai_bsc["clockin_time_to"] = clockin_time_to;

                // useEfectの途中で日付がStringになる場合があるためnew
                newCalender.pop();
                newCalender.push({...kintai_bsc, date: new Date(kintai_bsc.date)});
            }
        })
    });

    return newCalender;
}

/**
 * hh:mm形式の時刻をミリ秒に換算する
 * @param {String} tgtTime 時刻
 * @returns {number} msec ミリ秒
 */
export function getMsec(tgtTime) {
    
    if(!tgtTime)  return 0;

    var [hour, min] = tgtTime.split(':');
    var msec = (hour * 3600 + min * 60) * 1000;
    return msec;
}

/**
 * ミリ秒をhh:mm形式に換算する
 * @param {Number} tgtTime msec ミリ秒
 * @returns {String} tgtTime 時刻
 */
export function getHHmm(tgtTime) {
    if(tgtTime===undefined || tgtTime === 0){
        return "";
    }else{
        var hour = Math.floor(tgtTime/1000/60/60);
        var min = Math.floor(tgtTime/1000/60)%60;
        // var hhmm = ("00"+hour).slice(-2) + ":" + ("00"+min).slice(-2);
        // var hhmm = hour + ":" + min;
        var hhmm = hour + ":" + ("00"+min).slice(-2);
        return hhmm;
    }
}
/**
 * ミリ秒をhh:mm形式に換算する。TextField用2桁0埋
 * @param {Number} tgtTime msec ミリ秒
 * @returns {String} tgtTime 時刻
 */
export function getHHmmForTextField(tgtTime) {
    // if(tgtTime===undefined || tgtTime === 0){
    if(tgtTime===undefined){
        return "";
    }else{
        var hour = Math.floor(tgtTime/1000/60/60);
        var min = Math.floor(tgtTime/1000/60)%60;
        var hhmm = ("00"+hour).slice(-2) + ":" + ("00"+min).slice(-2);
        return hhmm;
    }
}

/**
 * ミリ秒から時間数(分数切り捨て)を求める。
 * @param {Number} tgtTime msec ミリ秒
 * @returns {Number} hours 時刻
 */
export function getHours(tgtTime) {
    
    if(tgtTime===undefined || tgtTime === 0){
        return "";
    }else{
        return Math.floor(tgtTime/1000/60/60);
    }
}

/**
 * ミリ秒から分数(1時間に満たないあまり)を求める。
 * @param {Number} tgtTime msec ミリ秒 
 * @returns {Number} minutes 分数
 */
export function getMinutes(tgtTime) {
    
    if(tgtTime===undefined || tgtTime === 0){
        return "";
    }else{
        return Math.floor(tgtTime/1000/60)%60;
    }
}

/**
 * シフト開始日(前回の15日)を取得する
 * @param {Date} tgtDay 日付
 * @returns {Date} shiftStartDay シフト開始日
 */
export function getShiftStartDay(tgtDay) {

    var refDay = SHIFT_START_DAY;

    var y = tgtDay.getFullYear();
    var m = tgtDay.getMonth();
    if(tgtDay.getDate() < refDay){
        m = m-1
    }

    var shiftStartDay = new Date(y, m, refDay, 0, 0, 0);

    return shiftStartDay;
}

/**
 * 待ち時間の設定
 * @param {Number} waitMsec 待ち時間msec
 */
export function sleep(waitMsec) {
    var startMsec = new Date();
    while (new Date() - startMsec < waitMsec);
  }


/**
 * カレンダーの色設定
 * @param {Date} targtDate 
 * @returns {String} 色
 */
export function getDayofWeekColor(targtDate, holidays) {

    const dayOfWeek = targtDate.getDay();

    if(dayOfWeek===0 || holidays[formatDate(targtDate)]){
        return "mistyrose";
    }else if(dayOfWeek===6){
        return "powderblue";
    }else{
        return "white"
    }
  }


/**
 * 2つのオブジェクトが等価であるかどうかを検証します。
 * @param {Object} obj1 比較するオブジェクト1
 * @param {Object} obj2 比較するオブジェクト2
 * @returns {boolean} オブジェクトが等価である場合はtrue、そうでない場合はfalse
 */
export function areObjectsEqual(obj1, obj2) {

    if(!obj1 || !obj2) return true
    
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
      return false; // プロパティの数が異なる場合は等価ではない
    }
  
    for (let key of keys1) {
      const value1 = obj1[key];
      const value2 = obj2[key];
  
      if (typeof value1 === "object" && typeof value2 === "object") {
        if (!areObjectsEqual(value1, value2)) {
          return false; // ネストされたオブジェクトが等価ではない場合は等価ではない
        }
      } else if (value1 !== value2) {
        return false; // プロパティの値が等しくない場合は等価ではない
      }
    }
  
    return true; // すべてのプロパティと値が等しい場合は等価とみなす
  }

  /**
 * オブジェクトまたは配列のディープコピーを行います。
 * @param {Object|Array} obj - ディープコピーするオブジェクトまたは配列。
 * @returns {Object|Array} - ディープコピーされたオブジェクトまたは配列。
 */
export function deepCopy(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    let copy;
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0; i < obj.length; i++) {
        copy[i] = deepCopy(obj[i]);
      }
    } else {
      copy = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          copy[key] = deepCopy(obj[key]);
        }
      }
    }
  
    return copy;
  }


  export function getUserName(Users, user_id){
    const user = Users.find(p=>p.user_id === user_id);
    if(user){
        return user.first_name + " " + user.second_name
    }
    return 
}
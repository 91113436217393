import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Kintai_Summary_Info } from "../classes/Kintai_Summary_Info_class";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as commonFnc from '../common/commonFnc';
import {GlobalContext} from '../globalState'


// name: ApprovalUserListTable
// summary: 【承認者用画面】承認対象の職員一覧を表示する。
// prop: kintaiSummaryInfos 勤怠承認サマリーの一覧
// prop: Department_msts 部署情報
// prop: Users ユーザ情報
// prop: setKintai_bscs 勤怠情報のセッター


const ApprovalUserListTable = (props) =>{
    
    // 01_props
    const {
        kintaiSummaryInfos, 
        Department_msts, 
        Users, 
        setKintai_bscs, 
        setShiftStartDay, 
        serchCondition, 
        setDisplay_user_info,
        allUser_Kintai_bscs,
        loading,
    } = props;
    const history = useHistory();
    const limits = useContext(GlobalContext).limits;
    const lastday = new Date(serchCondition.target_date_to).getDate();


    
    var userNameHash = {};
    Users.map((user) => (
        userNameHash[user.user_id] = user.first_name + " " + user.second_name
    ));

    // 02_useState
    const [kintaiSummaryInfos_view, setKintaiSummaryInfos_view] = useState([]);
    useEffect(()=>{
        let tmp_kintaiSummaryInfos_view = [];

        kintaiSummaryInfos.map((kintaiSummaryInfo, index) => {
            const tmp_user = Users.find(item => item.user_id === kintaiSummaryInfo._id);
            
            if(tmp_user){

                // 検索条件が設定されていればそれ以外をスキップ 
                if(serchCondition.target_deptgr_cd.length > 1 && tmp_user.organization_cd !== serchCondition.target_deptgr_cd) return;
                if(serchCondition.target_dept_cd.length > 1 && tmp_user.department_cd !== serchCondition.target_dept_cd) return;
                if(serchCondition.target_user_id.length > 1 && tmp_user.user_id !== serchCondition.target_user_id) return;
                if(serchCondition.target_user_name.length > 1 && userNameHash[tmp_user.user_id]?.indexOf(serchCondition.target_user_name)===-1) return;


                const tmp_dept = Department_msts.find(item => item.department_cd === tmp_user?.department_cd);
                const tmp_deptgr = Department_msts.find(item => item.department_cd === tmp_dept?.highly_department_cd);
                const tmp_kintai_bscs = allUser_Kintai_bscs.filter(item=>item.user_id === kintaiSummaryInfo._id);
    
                tmp_kintaiSummaryInfos_view.push(new Kintai_Summary_Info(kintaiSummaryInfo, tmp_user, tmp_dept, tmp_deptgr, Users, tmp_kintai_bscs));
            }
        });


        setKintaiSummaryInfos_view(tmp_kintaiSummaryInfos_view);
    },[kintaiSummaryInfos])

    // 03_独自関数(eventHandlerなど)

    const handlerClickNameButton = async (Kintai_bsc) => {
        
        // 勤怠一覧を初期化。遷移先で再取得
        setKintai_bscs([]);   

        let tmp_user = Users.find(item => item.user_id === Kintai_bsc.user_id);
        setDisplay_user_info(tmp_user);

        var d = new Date(serchCondition.target_date_from);
        setShiftStartDay(d);    

        history.push('/Kintai');

    }

    const style = {
        tableCellBase:{
            fontSize:'0.8rem',
            padding:'3px',
            "& .MuiTableCell-root": { padding: "8px" }
        },
    }

    return (
        <>
            {loading ? <div class="loader">Loading...</div> :
            <TableContainer component={Paper} style={{ maxHeight: '65vh'}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{...style.tableCellBase, minWidth:100}} >施設名</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase, minWidth:100}} >部署名</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >職員番号</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase, minWidth:100}} >氏名</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >時間</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >休憩</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >時間外</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >深夜</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >遅刻</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >早退</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >外出</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >業務外</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >公休</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >有給</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase, "borderLeft":"solid 1px lightgray"}} >申請前</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >一次待ち</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >二次待ち</TableCell>
                            <TableCell align="center" style={{...style.tableCellBase}} >確定</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {kintaiSummaryInfos_view.sort((a, b) => a.department_cd < b.department_cd ? -1 : 1).map((kintaiSummaryInfo_view,index)=>(
                            
                            <TableRow key={index}>
                                <TableCell align="center" style={{...style.tableCellBase}} >{kintaiSummaryInfo_view.organization_name}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{kintaiSummaryInfo_view.department_name}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} ><Button onClick={()=>handlerClickNameButton(kintaiSummaryInfo_view)}><span className="link">{kintaiSummaryInfo_view.user_id}</span></Button></TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{kintaiSummaryInfo_view.user_name}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{commonFnc.getHHmm(kintaiSummaryInfo_view.sum_work_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{commonFnc.getHHmm(kintaiSummaryInfo_view.sum_rest_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}}  className={kintaiSummaryInfo_view.sum_over_time>limits["LIMIT_OVER_TIME"]?"alert":""}>{commonFnc.getHHmm(kintaiSummaryInfo_view.sum_over_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{commonFnc.getHHmm(kintaiSummaryInfo_view.sum_midnight_over_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{commonFnc.getHHmm(kintaiSummaryInfo_view.sum_behind_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{commonFnc.getHHmm(kintaiSummaryInfo_view.sum_early_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{commonFnc.getHHmm(kintaiSummaryInfo_view.sum_out_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{commonFnc.getHHmm(kintaiSummaryInfo_view.sum_training_time)}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{kintaiSummaryInfo_view.sum_public_holiday_time/8}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{kintaiSummaryInfo_view.sum_paid_holiday_time/8}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase, "borderLeft":"solid 1px lightgray"}} >{kintaiSummaryInfo_view.count_before}/{lastday}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{kintaiSummaryInfo_view.count_wait1}/{lastday}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}} >{kintaiSummaryInfo_view.count_wait2}/{lastday}</TableCell>
                                <TableCell align="center" style={{...style.tableCellBase}}  className={kintaiSummaryInfo_view.count_commit!==lastday?"commit_error":"commit_completed"}>{kintaiSummaryInfo_view.count_commit}/{lastday}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </>
    );
}
export default ApprovalUserListTable;